body { background: #ECEFF1; color: rgba(0,0,0,0.87); font-family: Roboto, Helvetica, Arial, sans-serif; margin: 0; padding: 0;position: relative;}
.default { background: white; max-width: 360px; margin: 100px auto 16px; padding: 32px 24px; border-radius: 3px; }
.default h2 { color: #ffa100; font-weight: bold; font-size: 16px; margin: 0 0 8px; }
.default h1, .displayedMessage { font-size: 22px; font-weight: 300; color: rgba(0,0,0,0.6); margin: 0 0 16px;}
.default p { line-height: 140%; margin: 16px 0 24px; font-size: 14px; }
.default a { display: block; text-align: center; background: #039be5; text-transform: uppercase; text-decoration: none; color: white; padding: 16px; border-radius: 4px; margin:10px }
.default, .default a { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
#load { color: rgba(0,0,0,0.4); text-align: center; font-size: 13px; }
@media (max-width: 600px) {
  body, .default { margin-top: 0; background: white; box-shadow: none; }
  body { border-top: 16px solid #ffa100; }
}

#messagedisplay {margin:0px;width:80%; height:80vh; position:absolute; top: 40px; left:50%; transform: translate(-50%,0%); border:1px solid silver; border-radius:5px}
@media (max-height:200px) {
  #messagedisplay {height:65vh}
}
#messagedisplay #close {color:#ffa100;float:right; font-size:20px; font-weight:bold; display:inherit;}
#messagedisplay #close:hover {cursor:pointer}
#messagedisplay #messageContainer {max-height:100%; overflow-y:scroll}
.deafult a:hover {cursor:pointer}

#languageSelect * {padding:5px}
#languageSelect img {width:40px; height:25px; float:left}
#languageSelect img:hover {box-shadow: 1px 0px 10px 0px #039be5;cursor: pointer;}
#languageSelect p {transform: translate(0px,-5px)}
